import { InputAdornment, Typography, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ReactComponent as NotepadIcon } from "../../images/notepadicon.svg";
import { ReactComponent as CheckedIcon } from "../../images/checkvector.svg";
import { ReactComponent as CrossIcon } from "../../images/crossvector.svg";
import WrongAnswer from "./wrongAnswer";
import { Select, MenuItem } from "@material-ui/core";
import { fetchExamLanguages } from "./api";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "75%",
    margin: "2rem auto",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0rem auto",
    },
  },
  containerInner: {
    margin: "3rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    background: "#D7DAF2",
    padding: "0.4rem 3rem",
    borderRadius: "6px",
    "& .MuiTypography-h3": {
      color: "#252525",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "2.625rem",
      textTransform: "capitalize",
    },
  },
  notepadIcon: {
    margin: "0 0.5rem",
  },
  quizContainer: {
    background: "#FCFCFC",
    padding: "1rem 1rem ",
    "& img": {
      width: "20%",
      [theme.breakpoints.down("xs")]: {
        width: "70%",
      },
    },
  },
  direction: {
    margin: "2rem 0",
  },
  question: {
    "& .MuiTypography-caption": {
      color: "#727272",
      fontSize: "0.8rem",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "2.625rem",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h6": {
      color: "#252525",
      fontSize: "0.95rem",
      fontStyle: "normal",
      lineHeight: "1.625rem",
      textTransform: "capitalize",
    },
  },
  optionsContainer: {
    margin: "3rem 0",
  },
  options: {
    margin: "1rem 0",
    padding: "0.6rem",
    background: "#FFF",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.06)",
    "& .MuiTypography-h6": {
      width: "93%",
      color: "#252525",
      fontSize: "0.8rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "1.625rem",
      textTransform: "capitalize",
    },
  },
  rightOption: {
    background: "#EEFFF0",
    border: "2px solid #AAFFB2",
  },
  wrongOption: {
    background: "#FFF2F2",
    border: "2px solid #FFC1C1",
  },
  squareBox: {
    width: "28px",
    height: "28px",
    backgroundColor: "rgba(241, 241, 241, 1)",
    fontWeight: "500",
    color: "rgba(33, 33, 33, 1)",
    fontSize: "18px",
    textTransform: "capitalize",
    borderRadius: "4px",
    fontFamily: "'Jost', sans-serif",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  redSquareBox: {
    backgroundColor: "rgba(255, 57, 57, 1)",
    color: "white",
  },
  greenSquareBox: {
    backgroundColor: "rgba(0, 169, 17, 1)",
    color: "white",
  },
  crossIcon: {
    color: "rgba(255, 252, 244, 1)",
    padding: "0.3rem",
    width: "13px",
    height: "13px",
    backgroundColor: "rgba(255, 57, 57, 1)",
    borderRadius: "6px",
  },

  checkIcon: {
    color: "rgba(255, 255, 255, 1)",
    padding: "0.3rem",
    width: "13px",
    height: "13px",
    backgroundColor: "rgba(0, 169, 17, 1)",
    borderRadius: "6px",
  },
}));

function QuestionsAndOptions({ examResultData }) {
  const classes = useStyles();
  const [selectLanguage, setSelectLanguage] = useState("english");
  const [languages, setLanguages] = useState([]);
  const handlefetchExamLanguages = async (testSeriesId) => {
    try {
      const languageData = await fetchExamLanguages(testSeriesId);
      if (languageData && languageData.length > 0) {
        setLanguages(
          languageData.map((lang) => ({
            label:
              lang.language.charAt(0).toUpperCase() + lang.language.slice(1),
            value: lang.language,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    const testSeriesId = sessionStorage.getItem("test_series_id");
    handlefetchExamLanguages(testSeriesId);
  }, []);

  const handleChangeLanguage = (event) => {
    setSelectLanguage(event.target.value);
  };

  const getAlphabetLetter = (index) => {
    return String.fromCharCode(65 + index);
  };

  return (
    <div className={classes.container}>
      {/* Language selection dropdown */}
      <div style={{ textAlign: "center", margin: "1rem" }}>
        <Select
          className={classes.langoption}
          variant="outlined"
          onChange={handleChangeLanguage}
          value={selectLanguage}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>Select language</em>
          </MenuItem>
          {languages.map((lang) => (
            <MenuItem key={lang.value} value={lang.value}>
              {lang.label}
            </MenuItem>
          ))}
        </Select>
      </div>

      {examResultData.result.map((item, index) => {
        const correctOption = item.options.find((opt) => opt.correct_option);
        const selectedOption = item.options.find((opt) => opt.selected_option);
        const isNoOptionSelected = !selectedOption;

        return (
          <div key={item.id} className={classes.containerInner}>
            <div className={classes.heading}>
              <NotepadIcon className={classes.notepadIcon} />
              <Typography variant="h3">
                Question {index + 1} / {examResultData.result.length}
              </Typography>
            </div>
            <div className={classes.quizContainer}>
              <div className={classes.question}>
                <Typography
                  variant="caption"
                  dangerouslySetInnerHTML={{
                    __html:
                      selectLanguage === "english"
                        ? item.question_en
                        : item.question_hi,
                  }}
                />
              </div>
              <div className={classes.optionsContainer}>
                {item.options.map((option, optIndex) => (
                  <div
                    key={option.id}
                    className={`${classes.options} 
      ${option.correct_option ? classes.rightOption : ""} 
      ${
        selectedOption &&
        option.id === selectedOption.id &&
        !option.correct_option
          ? classes.wrongOption
          : ""
      }`}
                  >
                    <InputAdornment position="start">
                      <div className={`${classes.squareBox}`}>
                        {getAlphabetLetter(optIndex)}
                      </div>
                    </InputAdornment>
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{
                        __html:
                          selectLanguage === "english"
                            ? option.option_en
                            : option.option_hi,
                      }}
                    />
                    <InputAdornment position="end">
                      {option.correct_option && (
                        <CheckedIcon className={classes.checkIcon} />
                      )}
                      {selectedOption &&
                        option.id === selectedOption.id &&
                        !option.correct_option && (
                          <CrossIcon className={classes.crossIcon} />
                        )}
                    </InputAdornment>
                  </div>
                ))}
              </div>
              {/* Display WrongAnswer component */}
              {isNoOptionSelected ? (
                <WrongAnswer
                  correctOption={correctOption}
                  options={item.options}
                  selectLanguage={selectLanguage}
                  isNoOptionSelected={isNoOptionSelected}
                />
              ) : selectedOption && correctOption.id !== selectedOption.id ? (
                <WrongAnswer
                  correctOption={correctOption}
                  options={item.options}
                  selectLanguage={selectLanguage}
                />
              ) : null}

              {/* Display success message if the answer is correct */}
              {selectedOption && correctOption.id === selectedOption.id && (
                <Typography variant="h6" style={{ color: "green" }}>
                  Your Answer is Correct!
                </Typography>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default QuestionsAndOptions;
