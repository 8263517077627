import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import CheckImage from "../../images/checkimage.png";
import { ReactComponent as DiamondIcon } from "../../images/listdiamond.svg";
import { decryptData } from "../../crypto";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "50%",
    margin: "5rem auto",
    textAlign: "center",
    "& .MuiTypography-h3": {
      fontSize: "2rem",
      color: "#303030",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "& .MuiTypography-body1": {
      color: "#2D2C2C",
      fontSize: "1.025rem",
    },
    "& .MuiList-root": {
      width: "85%",
      margin: "0 auto",
    },
  },
  heading: {
    width: "90%",
    margin: "1rem auto",
  },
  instructionContainer: {
    marginTop: "1.5rem",
    padding: "1rem",
    background: "#F4F7FF",
  },
}));

function ThankyouPage() {
  const classes = useStyles();
  const navigate = useNavigate();

  const location = useLocation();
  const testSeriesName = location.state.testSeriesName;

  const navigateToExamScore = () => {
    navigate("/examscore");
  };

  return (
    <div className={classes.container}>
      <div>
        <img src={CheckImage} alt="Check" />
      </div>
      <div className={classes.heading}>
        <Typography variant="h3">
          Thank you for taking an online {testSeriesName} exam with ParikshaDO.
        </Typography>
      </div>
      <div className={classes.instructionContainer}>
        <List>
          <ListItem>
            <ListItemIcon>
              <DiamondIcon />
            </ListItemIcon>
            <ListItemText primary="A proctor has closed your exam window." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DiamondIcon />
            </ListItemIcon>
            <ListItemText primary="If you believe this was in error, please contact Invigilator." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DiamondIcon />
            </ListItemIcon>
            <ListItemText primary="You may now close the application or take our survey." />
          </ListItem>
        </List>
      </div>

      {/* <div>
      <Button variant="contained" color="primary" onClick={navigateToExamScore}>
        View Exam Score
      </Button>

</div> */}
    </div>
  );
}

export default ThankyouPage;
