import React, { useState, useEffect } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import FinalScore from "./finalScore";
import Answers from "./answers";
import { useLocation } from "react-router-dom";
import resultError from "../../images/resultError.jpeg";

const useStyles = makeStyles((theme) => ({
  container: {},
  headingMain: {
    width: "100%",
    background: "#4565B7",
    "& .MuiTypography-h3": {
      color: "#FFF",
      textAlign: "center",
      fontSize: "1.3rem",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "2.625rem",
      textTransform: "capitalize",
    },
  },
}));

function ExamScore() {
  const classes = useStyles();
  const location = useLocation();

  // const examResultData = JSON.parse(location.state?.examResult);

  let examResultData;
  try {
    examResultData = JSON.parse(location.state?.examResult);
    console.log("examResultData", examResultData);
  } catch (error) {
    console.error("Error parsing examResultData:", error);
  }

  const [instantResultState, setInstantResultState] = useState(
    sessionStorage.getItem("examInstantResult")
  );

  // const examResultData = JSON.parse(sessionStorage.getItem("examResult"));

  useEffect(() => {
    setInstantResultState(sessionStorage.getItem("examInstantResult"));
    console.log(sessionStorage.getItem("examInstantResult"));
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.headingMain}>
        <Typography variant="h3">Exam Score!</Typography>
      </div>

      {/* {examResultData && examResultData.summary && <FinalScore examResultData={examResultData} />}
      {instantResultState === "with_answer" && examResultData && examResultData.summary && examResultData.result && <Answers examResultData={examResultData} />} */}

      {examResultData && examResultData.summary ? (
        <>
          <FinalScore examResultData={examResultData} />
          {examResultData.result && <Answers examResultData={examResultData} />}
        </>
      ) : (
        <div>
          <Typography variant="body1">
            Result With Answers data not available for now.
          </Typography>
          <img src={resultError} alt="Result Error" />
        </div>
      )}
    </div>
  );
}

export default ExamScore;
