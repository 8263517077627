import React from "react";
import { Typography, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  profileSection: {
    height: "95vh",
    padding: "1rem 0.8rem",
    boxShadow: "0px 8px 15px #D7DAF2",
    zIndex: "1200",
  },
  profilesectioninner: {
    display: "flex",
    justifyContent: "space-between",
  },
  imagecontainer: {
    width: " 40%",
    display: "flex",
    background: "#E0E0E2",
    borderRadius: "10px",
  },
  profilephoto: {
    aspectRatio: "1 / 1",
    borderRadius: "10px",
  },
  learnerinfo: {
    marginLeft: "0.5rem",
    "& .MuiTypography-body1": {
      color: "#000",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
      textTransform: "capitalize",
      TextTrack: "wrap",
    },
    "& .MuiTypography-body2": {
      color: "#000",
      fontFamily: "Satoshi",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: "500",
      textTransform: "capitalize",
    },
  },
  questionpallatemain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  timer: {
    width: "80%",
    background: "#4565B7",
    borderRadius: "10px",
    padding: "0.7rem",
    margin: "0.5rem 0",
    textAlign: "center",
    "& .MuiTypography-h4": {
      color: "#FFF",
      fontFamily: "Playfair Display",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: "800",
      textTransform: "capitalize",
    },
  },
  numberbox: {
    background: "#F0F2FF",
    width: "80%",
    padding: "0.4rem",
    borderRadius: "6px",
  },
  numberboxhead: {
    background: "#4565B7",
    borderRadius: "6px 6px 0px 0px",
    padding: "0.7rem",
    "& .MuiTypography-h4": {
      color: "#FFF",
      fontFamily: "Playfair Display",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: "800",
      textTransform: "capitalize",
    },
  },
  questionNumberBoxmain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    height: "40vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#4565B7",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#39476B",
    },
  },
  questionNumberBoxmaininner: {
    width: "95%",
  },
  questionNumberBox: {
    minWidth: "28px !important",
    height: "28px !important",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    padding: "0",
    margin: "0.3rem",
    backgroundColor: "#D7DAF2",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    "&.selected": {
      backgroundColor: "#4565B7",
      color: "white",
      fontWeight: "bold",
    },
    "&.answered": {
      backgroundColor: "#04A10B",
      color: "white",
      fontWeight: "bold",
    },
    "&.marked": {
      backgroundColor: "yellow",
      color: "black",
      fontWeight: "bold",
    },
    "&.not_attempted": {
      backgroundColor: "#F26809",
      color: "white",
      fontWeight: "bold",
    },
    "&.not_viewed": {
      backgroundColor: "#FF0000",
      color: "white",
      fontWeight: "bold",
    },
    "&.marked_no_answer": {
      background: "Purple",
      color: "white",
      fontWeight: "bold",
    },
  },
  selectedQuestion: {
    width: "125px",
    margin: "0 0.5rem",
    padding: "0.5rem",
    background: "#4565B7",
    borderRadius: "6px",
    color: "#FFF",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "capitalize",
    border: "1px solid  #4565B7",
    boxShadow: "none",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    "&:hover": {
      background: "#4565B7",
    },
  },
  bgwhite: {
    // height: "40vh",
    background: "white",
    border: "1px solid #D7DAF2",
    borderRadius: "6px",
  },
  legend: {
    width: "100%",
    marginTop: "0.5rem",
    display: "flex",
    flexWrap: "wrap",
  },
  legendinner2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "0.1rem",
    "& .MuiTypography-body1": {
      color: "#727272",
      fontSize: "0.9rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
    },
    "& span": {
      margin: "0.2rem",
      minWidth: "15px",
      minHeight: "15px",
    },
  },
  attempted: {
    background: "#04A10B",
  },
  notanswered: {
    background: "#F26809",
  },
  markedanswer: {
    background: "yellow",
  },
  markednotanswer: {
    background: "Purple",
  },
  notviewed: {
    background: "#D7DAF2",
  },
  current: {
    backgroundColor: "#4565B7",
  },
});

const ExamProfileSection = ({
  profilePic,
  name,
  number,
  formatTime,
  remainingTime,
  sections,
  flattenQuestions,
  questionRefs,
  handleQuestionSelectByQuestionId,
  selectedQuestion,
  questionStatus,
  toggleDrawer,
  closestatus,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.profileSection}>
        <div className={classes.profilesectioninner}>
          <div className={classes.imagecontainer}>
            <img
              className={classes.profilephoto}
              src={profilePic}
              alt="Profile"
              width="100%"
            />
          </div>
          <div className={classes.learnerinfo}>
            <Typography variant="body1">{name}</Typography>
            <Typography variant="body1">{number}</Typography>
          </div>
          <div>
            {closestatus === "true" && (
              <IconButton onClick={toggleDrawer}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>

        <div className={classes.questionpallatemain}>
          <div className={classes.timer}>
            <Typography variant="h4" color="primary">
              Time Left: {formatTime(remainingTime)}
            </Typography>
          </div>
          <div className={classes.numberbox}>
            {sections.length > 0 && (
              <div className={classes.bgwhite}>
                <div className={classes.numberboxhead}>
                  <Typography variant="h4" className={classes.questionLabel}>
                    Questions
                  </Typography>
                </div>
                <div className={classes.questionNumberBoxmain}>
                  <div className={classes.questionNumberBoxmaininner}>
                    {flattenQuestions.map(({ id, number }, index) => {
                      return (
                        <Button
                          key={id}
                          id={`question-${id}`}
                          ref={questionRefs[index]} // Assign the ref here
                          onClick={(event) =>
                            handleQuestionSelectByQuestionId(id, event)
                          }
                          className={`${classes.questionNumberBox} ${
                            selectedQuestion && selectedQuestion.id === id
                              ? "selected"
                              : questionStatus[id] || ""
                          }`}
                        >
                          {number}
                        </Button>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classes.legend}>
            <div className={classes.legendinner2}>
              <span className={classes.current}></span>{" "}
              <Typography>Current Question</Typography>
            </div>
            <div className={classes.legendinner2}>
              <span className={classes.attempted}></span>{" "}
              <Typography>Answered</Typography>
            </div>
            <div className={classes.legendinner2}>
              <span className={classes.notanswered}></span>{" "}
              <Typography>Not Answered</Typography>
            </div>
            <div className={classes.legendinner2}>
              <span className={classes.markedanswer}></span>{" "}
              <Typography>Marked & Answered</Typography>
            </div>
            <div className={classes.legendinner2}>
              <span className={classes.markednotanswer}></span>{" "}
              <Typography>Marked & Not Answered</Typography>
            </div>
            <div className={classes.legendinner2}>
              <span className={classes.notviewed}></span>{" "}
              <Typography>Not viewed</Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamProfileSection;
