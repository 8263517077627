import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import ProfileSection from "./profileSection";

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiPaper-root": {
      width: "80% !important",
    },
  },
}));

export default function TemporaryDrawer({
  exam_name,
  profilePic,
  examDuration,
  dob,
  email,
  name,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "white" }} />{" "}
          </Button>
          <Drawer
            className={classes.drawer}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <ProfileSection
              exam_name={exam_name}
              profilePic={profilePic}
              examDuration={examDuration}
              dob={dob}
              email={email}
              name={name}
              closestatus="true"
              toggleDrawer={toggleDrawer}
              anchor={anchor}
            />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
