import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import ExamProfileSection from "./examprofilesection";

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiPaper-root": {
      width: "80% !important",
    },
  },
}));

export default function TemporaryDrawer({
  profilePic,
  name,
  number,
  formatTime,
  remainingTime,
  sections,
  flattenQuestions,
  questionRefs,
  handleQuestionSelectByQuestionId,
  selectedQuestion,
  questionStatus,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({});

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log("state123", open, anchor);

    setState({ ...state, [anchor]: open });
  };
  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "white" }} />{" "}
          </Button>
          <Drawer
            className={classes.drawer}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <ExamProfileSection
              profilePic={profilePic}
              name={name}
              number={number}
              formatTime={formatTime}
              remainingTime={remainingTime}
              sections={sections}
              flattenQuestions={flattenQuestions}
              questionRefs={questionRefs}
              handleQuestionSelectByQuestionId={
                handleQuestionSelectByQuestionId
              }
              selectedQuestion={selectedQuestion}
              questionStatus={questionStatus}
              toggleDrawer={toggleDrawer(anchor, false)}
              closestatus= "true"
            />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
