const DummyInstruction = () => {
  return (
    <div>
      <p>Please read the instructions carefully</p>
      <p>General Instructions:</p>
      <p>
        Please note all questions will appear in your default language. This
        language can be changed for a particular question later on.
      </p>
      <p>
        I have read and understood the instructions. All computer hardware
        allotted to me are in proper working condition. I declare that
        prohibited gadget like mobile phone, bluetooth devices etc. /any
        prohibited material with me into the Examination Hall. I agree the
        debarred from this Test and/or to disciplinary action, which may include
        ban from future Tests / Examinations.
      </p>
      <ol>
        <li>
          The clock will be set at the server. The countdown timer in the top
          right corner of screen will display the remaining time available for
          you to complete the examination. When the timer reaches zero, the
          examination will end by itself. You will not be required to end or
          submit your examination.
        </li>
        <li>
          The Question Palette displayed on the right side of screen will show
          the status of each question using one of the following symbols:
          <ul>
            <li>You have not visited the question yet</li>
            <li>You have not answered the question</li>
            <li>You have answered the question</li>
            <li>
              You have NOT answered the question, but have marked the question
              for review.
            </li>
          </ul>
          <p>
            The question(s) 'Answered and Marked for Review' will be considered
            for evaluation.
          </p>
        </li>
        <li>
          The Marked for Review status for a question simply indicates that you
          would like to look at that question again.
        </li>
        <li>
          You can click on the ' &gt; ' arrow which appears to the left of
          question palette to collapse the question palette thereby maximizing
          the question window. To view the question palette again, you can click
          on ' &lt; ' which appears on the right side of question window.
        </li>
        <li>
          You can click on your 'Profile' image on top right corner of your
          screen to change the language during the exam for entire question
          paper. On clicking of Profile image you will get a drop-down to change
          the question content to the desired language.
        </li>
      </ol>
    </div>
  );
};

export default DummyInstruction;
