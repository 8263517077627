import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Button,
  Modal,
  Checkbox,
  MenuItem,
  Select,
} from "@material-ui/core";
import { fetchExamLanguages, fetchQuestionsData, fetchProfileApi } from "./api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { examStartApi } from "./api";
import DummyInstruction from "./dummyInstructions";
import ProfileDrawer from "./profileDrawer";
import ProfileSection from "./profileSection";
import LoaderSpinner from "./LoaderSpinner";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",

    "& .MuiTypography-h4": {
      color: "#FFF",
      background: "#4565B7",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "2rem",
      textTransform: "capitalize",
    },
  },
  mobilesection: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  section: {
    width: " 18%",
    padding: "1rem",
    background: "#FFF",
    boxShadow: "0px 8px 15px #D7DAF2",
    zIndex: "1200",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  Instructionsection: {
    maxWidth: "100%",
    height: "100vh",
    background: "#FFF",
    filter: "drop-shadow(0px 8px 20px #D7DAF2)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  popuptext: {
    display: "flex",
    alignItems: "flex-start",
    "& .MuiTypography-h6": {
      color: "#000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      fontWeight: "400",
      textAlign: "start",
      lineHeight: "1.25rem",
      textTransform: "capitalize",
    },
  },
  rednote: {
    color: "red",
    padding: "0 1rem 0.5rem 1rem",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "0.9rem",
    fontStyle: "normal",
    fontWeight: "500",
    textAlign: "start",
    lineHeight: "1.25rem",
    textTransform: "capitalize",
  },
  buttonContainer: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 32px 8.5px 14px",
    },
  },
  mt6: {
    marginTop: "6rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
    },
  },
  langoption: {
    marginRight: "1rem",
  },
  Instructionmain: {
    height: "100vh",
    display: "flex",
    width: "81%",
    alignContent: "space-around",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Instructioncontent: {
    margin: "20px",
    height: "85%",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "&::-webkit-scrollbar": {
      width: "20px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#4565B7",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#39476B",
    },
  },
  Instructionplusbutton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#FFF",
    background: "#4565B7",
    textAlign: "center",
    fontFamily: "Playfair Display",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: "700",
    textTransform: "capitalize",
    width: "100%",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    "& .MuiOutlinedInput-root": {
      background: "white",
      margin: "0.5rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 30px 10.5px 14px",
    },
  },
  languagecontrol: {
    width: "142px",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #000",
    background: "#FFF",
    marginTop: ".4rem",
  },
}));
const dummyInstruction = `Please read the instructions carefully
General Instructions:
Please note all questions will appear in your default language. This language can be changed for a particular question later on.

I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare tha prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall.I agree the debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations

1. The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will not be required to end or submit your examination.

2. The Question Palette displayed on the right side of screen will show the status of each question using one of the following symbols:

You have not visited the question yet
You have not answered the question
You have answered the question
You have NOT answered the question, but have marked the question for review.
The question(s) 'Answered and Marked for Review' will be considered for evaluation
3. The Marked for Review status for a question simply indicates that you would like to look at that question again.

4. You can click on the ' > ' arrow which appears to the left of question palette to collapse the question palette thereby maximizing the question window. To view the question palette again, you can click on ' < ' which appears on the right side of question window

5. You can click on your 'Profile' image on top right corner of your screen to change the language during the exam for entire question paper. On clicking of Profile image you will get a drop-down to change the question content to the desired language.`;

const Instructions = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // Profile state
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [examInstructions, setExamInstructions] = useState(dummyInstruction);
  const [examInstructionsHindi, setExamInstructionsHindi] = useState("");
  const [exam_name, setExamName] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("english");
  const [languages, setLanguages] = useState([]);
  const [examDuration, setExamDuration] = useState("");

  const fetchQuestions = async (test_series_id) => {
    try {
      const questionsData = await fetchQuestionsData(test_series_id);
      console.log("examDuration", questionsData);

      const storedExamDuration = questionsData.duration;
      setExamDuration(storedExamDuration);
      setExamName(questionsData.exam_name);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    const test_series_id = sessionStorage.getItem("test_series_id");
    if (test_series_id) {
      fetchQuestions(test_series_id);
    }
  }, []);

  const handleChangeLanguage = (event) => {
    setSelectLanguage(event.target.value);
  };

  const handleTestSeriesExamLanguages = async (test_series_id) => {
    try {
      const languageData = await fetchExamLanguages(test_series_id);

      if (languageData && languageData.length > 0) {
        setLanguages(
          languageData.map((lang) => ({
            label:
              lang.language.charAt(0).toUpperCase() + lang.language.slice(1),
            value: lang.language,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    const test_series_id = sessionStorage.getItem("test_series_id");
    handleTestSeriesExamLanguages(test_series_id);
  }, []);

  // Modal state
  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Retrieve data from sessionStorage
  // useEffect(() => {
  //   setExamInstructions(
  //     decryptData(sessionStorage.getItem("examInstructions"))
  //   );
  //   setExamInstructionsHindi(
  //     decryptData(sessionStorage.getItem("instructionsHindi"))
  //   );
  //   setExamName(decryptData(sessionStorage.getItem("exam_name")));
  // }, []);

  // useEffect(() => {
  //   if (selectLanguage === "hindi") {
  //     setExamInstructions(examInstructionsHindi);
  //   } else {
  //     setExamInstructions(
  //       decryptData(sessionStorage.getItem("examInstructions"))
  //     );
  //   }
  // }, [selectLanguage, examInstructionsHindi]);

  // user profile api
  const fetchProfileData = async () => {
    try {
      const candidateData = await fetchProfileApi();
      setProfilePic(candidateData.image_url);
      setName(candidateData.full_name);
      setEmail(candidateData.email);

      // Convert the incoming DOB format to "7 Dec 2003" format
      const rawDob = candidateData.learner_information.dob;
      const dobDate = new Date(rawDob);

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day = dobDate.getDate();
      const monthIndex = dobDate.getMonth();
      const year = dobDate.getFullYear();

      const formattedDob = `${day} ${monthNames[monthIndex]} ${year}`;

      setDob(formattedDob);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [remainingTime, setRemainingTime] = useState(3600);

  const handleNextButtonClick = () => {
    if (isChecked) {
      const test_series_id = sessionStorage.getItem("test_series_id");

      fetchExamStartApi(test_series_id);

      navigate("/test", { state: { selectLanguage: selectLanguage } });

      sessionStorage.setItem("loginTime", new Date().getTime());
      sessionStorage.setItem("examStarted", true);

      sessionStorage.setItem("remainingTime", remainingTime);

      // sessionStorage.setItem("languageSelected", selectLanguage);
      sessionStorage.removeItem("candidateLoggedinToInstructionsPage");
    } else {
      // alert("Please confirm that you have read the instructions.");
      toast.warning("Please confirm that you have read the instructions!");
    }
  };

  const fetchExamStartApi = async (test_series_id) => {
    try {
      // Fetching data from the examStartApi
      const responseData = await examStartApi(test_series_id);

      if (responseData && responseData.time_left) {
        // const examDurationInSeconds = (responseData.time_left) * 60;
        const examDurationInSeconds = responseData.time_left;

        setRemainingTime(examDurationInSeconds);
        sessionStorage.setItem(
          "remainingTime",
          examDurationInSeconds.toString()
        );

        console.log("responseData.time_left", responseData.time_left);
      }
    } catch (error) {
      console.error("Error fetching start time of the exam:", error);
    }
  };

  const instructionTexts = {
    english:
      "I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that no prohibited gadget like mobile phone, Bluetooth devices etc., or any prohibited material is with me in the Examination Hall. I agree that if found guilty of any malpractice, I may be debarred from this Test and/or subjected to disciplinary action, which may include a ban from future Tests/Examinations.",
    hindi:
      "मैंने निर्देश पढ़े और समझे हैं। मुझे आवंटित सभी कंप्यूटर हार्डवेयर ठीक काम कर रहे हैं। मैं घोषित करता हूं कि परीक्षा हॉल में मेरे पास कोई प्रतिबंधित गैजेट जैसे मोबाइल फोन, ब्लूटूथ उपकरण आदि या कोई भी प्रतिबंधित सामग्री नहीं है। मैं सहमत हूं कि यदि किसी भी अनुचित प्रक्रिया का दोषी पाया जाता है, तो मुझे इस परीक्षण से वंचित किया जा सकता है और/या अनुशासनात्मक कार्रवाई के अधीन किया जा सकता है, जिसमें भविष्य के परीक्षणों/परीक्षाओं से प्रतिबंध शामिल हो सकता है।",
  };
  const [instructionText, setInstructionText] = useState(
    instructionTexts.english
  );

  useEffect(() => {
    setInstructionText(instructionTexts[selectLanguage]);
  }, [selectLanguage]);

  return (
    <>
      {exam_name ? (
        <>
          <ToastContainer />
          <div className={classes.container}>
            {/* Profile Section */}
            {/* <div className={classes.section}>
          <Typography variant="h4">Profile Details</Typography>

          <div className={classes.profileImagecontainer}>
            <img
              src={profilePic}
              alt="Profile IMG"
              className={classes.profileImage}
            />
          </div>
          <div className={`${classes.dflex}  ${classes.mt1}`}>
            <Typography variant="body1">Exam Name : </Typography>
            <Typography variant="h6" className={`${classes.ml1}`}>
              {" "}
              {exam_name}
            </Typography>
          </div>
          <div className={`${classes.dflex}  ${classes.mt1}`}>
            <Typography variant="body1"> Exam Duration :</Typography>
            <Typography variant="h6" className={`${classes.ml1}`}>
              {" "}
              {examDuration} minutes
            </Typography>
          </div>
          <div className={`${classes.dflex}  ${classes.mt1}`}>
            <Typography variant="body1">Candidate Name :</Typography>
            <Typography variant="h6" className={`${classes.ml1}`}>
              {" "}
              {name}
            </Typography>
          </div>
          <div className={`${classes.dflex}  ${classes.mt1}`}>
            <Typography variant="body1">DOB :</Typography>
            <Typography variant="h6" className={`${classes.ml1}`}>
              {" "}
              {dob}
            </Typography>
          </div>
          <div className={`${classes.dflex}  ${classes.mt1}`}>
            <Typography variant="body1">Email : </Typography>
            <Typography variant="h6" className={`${classes.ml1}`}>
              {email}
            </Typography>
          </div>
        </div> */}
            <div className={classes.section}>
              <ProfileSection
                exam_name={exam_name}
                profilePic={profilePic}
                examDuration={examDuration}
                dob={dob}
                email={email}
                name={name}
                closestatus="false"
              />
            </div>

            {/* Instructions Section */}
            <div className={classes.Instructionmain}>
              <div className={classes.Instructionsection}>
                <div className={classes.Instructionplusbutton}>
                  <div>
                    <div className={classes.mobilesection}>
                      <ProfileDrawer
                        exam_name={exam_name}
                        profilePic={profilePic}
                        examDuration={examDuration}
                        dob={dob}
                        email={email}
                        name={name}
                      />
                    </div>
                  </div>

                  <Typography variant="h4">Instructions</Typography>
                  <Select
                    className={classes.langoption}
                    variant="outlined"
                    onChange={handleChangeLanguage}
                    value={selectLanguage}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em>Select language</em>
                    </MenuItem>
                    {languages.map((lang) => (
                      <MenuItem key={lang.value} value={lang.value}>
                        {lang.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={classes.Instructioncontent}>
                  {/* <div dangerouslySetInnerHTML={{ __html: examInstructions }} /> */}
                  <DummyInstruction />
                  <div className={`${classes.buttonContainer} ${classes.mt6}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModal}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>

              {/* Modal */}
              <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                className={classes.modal}
              >
                <div className={classes.modalContent}>
                  <div>
                    <Typography variant="h5" className={classes.rednote}>
                      Please note all questions will appear in your default
                      language. This language can be changed for a particular
                      question later on.
                    </Typography>
                    <div className={classes.popuptext}>
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                      <Typography variant="h6">{instructionText}</Typography>
                    </div>
                  </div>

                  <div className={classes.buttonContainer}>
                    <div>
                      <Select
                        className={classes.langoption}
                        variant="outlined"
                        onChange={handleChangeLanguage}
                        value={selectLanguage}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em>Select language</em>
                        </MenuItem>
                        {languages.map((lang) => (
                          <MenuItem key={lang.value} value={lang.value}>
                            {lang.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextButtonClick}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Modal>

              {/* Next Button */}
            </div>
          </div>
        </>
      ) : (
        <LoaderSpinner />
      )}
    </>
  );
};

export default Instructions;
